
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class MapboxMap extends Vue {
  @Prop({ required: true })
  private readonly location!: string;

  @Prop({ default: 500 })
  private readonly width!: number;

  @Prop({ default: 500 })
  private readonly height!: number;

  // Value between 0 and 22
  @Prop({ default: 17 })
  private readonly zoom!: number;

  private accessToken = 'pk.eyJ1IjoicHJvbGlzdCIsImEiOiJja2RzMWl4Z3kwazB3MnNueXo0bHBlMTZnIn0.LPvrgr3aMczOB-ENyEP9EA'

  private coordinated: string | null = null;

  private xCoord: number | null = null
  private yCoord: number | null = null

  get srcUrl (): string {
    return `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s-+555($${this.yCoord},${this.xCoord},14/${this.width}x${this.height}?access_token=${this.accessToken}`
  }

  mounted (): void {
    this.getCoordinates()
  }

  getCoordinates (): void {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.location)}.json?limit=1&types=address&access_token=${this.accessToken}`)
      .then(response => response.json())
      .then(data => {
        this.xCoord = data.features[0].center[0]
        this.yCoord = data.features[0].center[1]
      })
  }
}

